<template>
    <breadCrumb/>
    <Searvices/>
</template>

<script>
import breadCrumb from '../components/topSection/topSection.vue'
import Searvices from '../components/services/services.vue'
export default {
    components: {
        breadCrumb,
        Searvices
    },
    data(){
        return{}
    }
}
</script>